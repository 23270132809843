import { get, post, put, deletefn } from '@/http/http'

// 巡检点查询
// export const getInspecpoint = p => get('v1/inspecpoint/getinspecpoint', p)
export const getInspecpoint = p => post('v2/inspecpoint_v2/getinspecpoint', p)

// 巡检标签查询
export const addNameSelect = p => post('v2/inspecpoint_v2/getchecklabel', p)

// 巡检点添加
// export const addInspecpoint = p => post('v1/inspecpoint/addinspecpoint', p)
export const addInspecpoint = p => post('v2/inspecpoint_v2/addinspecpoint', p)

// 巡检点修改
// export const updateInspecpoint = p => put('v1/inspecpoint/updateinspecpoint', p)
export const updateInspecpoint = p => post('v2/inspecpoint_v2/updateinspecpoint', p)

// 巡检点删除
// export const deleteInspecpoint = p => deletefn('v1/inspecpoint/deleteinspecpoint', p)
export const deleteInspecpoint = p => post('v2/inspecpoint_v2/deleteinspecpoint', p)

// 巡检计划查询
// export const getInspecplan = p => get('v1/inspecplan/getinspecplan', p)
export const getInspecplan = p => post('v2/inspecplan/getinspecplan_pc', p)

// 巡检计划添加
// export const addInspecplan = p => post('v1/inspecplan/addinspecplan', p)
export const addInspecplan = p => post('v2/inspecplan/addinspecplan', p)

// 巡检计划详情
// export const getplanDetails = p => get('v1/inspecplan/getplandetails', p)
export const getplanDetails = p => post('v2/inspecplan/getplandetails', p)

// 巡检计划删除
// export const deleteInspecplan = p => deletefn('v1/inspecplan/deleteinspecplan', p)
export const deleteInspecplan = p => post('v2/inspecplan/deleteinspecplan', p)

// 巡检计划停用/启用
// export const updatePlandisa = p => put('v1/inspecplan/updateplandisa', p)
export const updatePlandisa = p => post('v2/inspecplan/updateplandisa', p)

// 巡检任务查询
// export const getInspectask = p => get('v1/inspecplan/getinspectask', p)
export const getInspectask = p => post('v2/inspecplan/getinspectask', p)

// 巡检任务 人员详情
export const taskUserQuery = p => post('v2/inspecplan/taskuser_point', p)

// 巡检任务 巡检上报查询
export const taskReport = p => post('v2/inspecplan/getinspecreport', p)

// 巡检督办
export const Instaskoversee = p => post('v1/inspecplan/instaskoversee', p)

// 巡检督办查询
export const getOversee = p => get('v1/inspecplan/getoversee', p)

// 巡检任务 导出
export const onSiteExport = p => post('v1/exportfile/export_inspectask', p)
