<template>
<!-- 巡检计划 -->
  <div class="setting_main_bg">
    <div class="onplan_cont">
      <div class="onplan_box">
        <div class="btn_group" v-if="roleBtnArray[0].power">
          <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
        </div>
        <div class="flexAC" :class="roleBtnArray[0].power === true?'ut_top':''">
          <el-input v-model="inplanname"  placeholder="请输入搜索内容" class="contInput"></el-input>
          <!-- <el-date-picker
            v-model="date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker> -->
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="onplan_table">
          <el-table
            stripe
            :data="planList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="单位名称"
              prop="companyname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="进度"
              width="80"
              prop="percentage">
              <template slot-scope="scope">
                <span :class="{ 'state1': scope.row.inplanstate ==='1','state2': scope.row.inplanstate ==='2','state3': scope.row.inplanstate ==='3','state4': scope.row.inplanstate ==='4', 'state5': scope.row.inplanstate ==='5'}">
                  {{ scope.row.percentage }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="巡检计划名称"
              prop="inplanname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="制定人"
              width="80"
              prop="username">
            </el-table-column>
            <el-table-column
              label="状态"
              width="80"
              prop="inplanstate">
              <template slot-scope="scope">
                <span :class="{ 'state1': scope.row.inplanstate ==='1','state2': scope.row.inplanstate ==='2','state3': scope.row.inplanstate ==='3','state4': scope.row.inplanstate ==='4', 'state5': scope.row.inplanstate ==='5'}">
                  {{ scope.row.complete }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="循环周期"
              prop="inplanweek,inplantype">
              <template slot-scope="scope">
                <span v-if="scope.row.inplantype == 1">{{scope.row.inplanweek}} (星期)</span>
                <span v-if="scope.row.inplantype == 2">{{scope.row.inplanweek}} (月)</span>
              </template>
            </el-table-column>
            <el-table-column
              label="巡检人员(人)"
              prop="usercount">
              <template slot-scope="scope">
                <span>{{scope.row.usercount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="巡检点(个)"
              prop="pointcount">
              <template slot-scope="scope">
                <span>{{scope.row.pointcount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="开始时间"
              prop="inplanstime">
            </el-table-column>
            <el-table-column
              label="结束时间"
              prop="inplanetime">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
                <!-- <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button> -->
                <el-button v-if="roleBtnArray[1].power" type="text" size="small" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
                <el-button v-if="roleBtnArray[2].power && scope.row.inplanstate === '1'" type="text" size="small" @click="handleStop(scope.row)">停用</el-button>
                <el-button v-if="roleBtnArray[2].power && scope.row.inplanstate === '2'" type="text" size="small" @click="handleStop(scope.row)">停用</el-button>
                <el-button v-if="roleBtnArray[2].power && scope.row.inplanstate === '3'" type="text" size="small" style="color: #999;">停用</el-button>
                <el-button v-if="roleBtnArray[2].power && scope.row.inplanstate === '4'" type="text" size="small" style="color: #999;">停用</el-button>
                <el-button v-if="roleBtnArray[2].power && scope.row.inplanstate === '5'" type="text" size="small" style="color: #999;">停用</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog title="巡检计划新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="巡检计划名称"  prop="inplanname">
          <el-input v-model="addfrom.inplanname"  placeholder="请输入巡检计划名称"></el-input>
        </el-form-item>
        <el-form-item label="巡检人员"   prop="inplanuser" >
          <el-select v-model="addfrom.inplanuser" multiple placeholder="请选择巡检人员" style="width:550px;">
            <el-option
                v-for="item in planUserOptions"
                :key="item.userid"
                :label="item.username"
                :value="item.userid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检点" prop="inplanpoint" >
          <el-select v-model="addfrom.inplanpoint"  multiple placeholder="请选择巡检点" style="width: 550px">
            <el-option
                v-for="item in planPointOptions"
                :key="item.inpointid"
                :label="item.inpointname"
                :value="item.inpointid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择时间"  prop="time">
          <el-date-picker
            v-model="addfrom.time"
            clearable
            type="daterange"
            style="width: 550px"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="循环周期"  prop="inplantype" >
          <el-radio-group v-model="addfrom.inplantype" @change="changeType" style="width: 550px">
            <el-radio label="1">日</el-radio>
            <el-radio label="2">月</el-radio>
          </el-radio-group>
          <el-select v-model="dayValue" multiple placeholder="请选择星期" style="width: 550px" v-if="showDay">
						<el-option
                v-for="item in dayOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
					</el-select>
          <el-select v-model="monthValue"  clearable  placeholder="请选择月份" style="width: 550px" v-if="showMonth">
						<el-option
                v-for="item in dayOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
						</el-option>
					</el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="巡检计划详情" :visible.sync="detailShow" width="800px" center class="gyDialog unit_addDialog">
      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">巡检计划基本信息</p>
          </div>
          <p class="udi_p">巡检计划名称：{{ detailInfo.inplanname }}</p>
          <div class="flexCB">
            <p class="udiIP">完成状态：
              <span v-if="detailInfo.inplanstate == 1">待完成</span>
              <span v-if="detailInfo.inplanstate == 2">进行中</span>
              <span v-if="detailInfo.inplanstate == 3">已结束</span>
            </p>
            <p class="udiIP">制定人：{{ detailInfo.username }}</p>
          </div>
          <div class="flexCB">
            <p class="udiIP">开始时间：{{ detailInfo.inplanstime }}</p>
            <p class="udiIP">结束时间：{{ detailInfo.inplanetime }}</p>
          </div>
          <p class="udi_p">循环周期：
            <span v-if="detailInfo.inplantype == 1">{{ detailInfo.inplanweek }} (星期)</span>
            <span v-if="detailInfo.inplantype == 2">{{ detailInfo.inplanweek }} (月)</span>
          </p>
        </div>
      </div>

      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">巡检人员</p>
          </div>
          <div style="display: flex;flex-wrap: wrap;">
            <p class="udi_s" v-for="(item, index) in inplanuser" :key="index">巡检人员：{{ item.username }}</p>
          </div>
        </div>
      </div>

      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">巡检点</p>
          </div>
          <div style="display: flex;flex-wrap: wrap;">
            <p class="udiS" v-for="(item, index) in inplanpoint" :key="index"><span>巡检点：{{ item.inpointname }}</span></p>
          </div>
        </div>
      </div>

      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">关联设备</p>
          </div>
          <div class="udi_c" v-for="(item, index) in equlist" :key="index">
            <div class="udi_r"><span>{{item.equname}}</span></div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 新版详情 -->
    <el-dialog :visible.sync="detailNewShow" width="800px" center class="unit_addDialog">
      <div class="flexCB">
        <div class="flexAC detail_l" style="border: none;"><div class="udi_k"></div><p class="udi_t">巡检计划基本信息</p></div>
        <div class="flexAC detail_r" ><div class="udi_k"></div><p class="udi_t">巡检人员({{ userList.length }})</p></div>
      </div>
      <div class="detail_info">
        <div class="detail_l">
          <p class="de_lr">单位名称：<span>{{ detailInfo.companyname }}</span></p>
          <p class="de_lr">巡检计划名称：<span>{{ detailInfo.inplanname }}</span></p>
          <p class="de_lr">状态：
            <span :class="{ 'sr1': detailInfo.inplanstate ==='1','sr2': detailInfo.inplanstate ==='2','sr3': detailInfo.inplanstate ==='3','sr4': detailInfo.inplanstate ==='4','sr5': detailInfo.inplanstate ==='5'}">
              {{ detailInfo.complete }}
            </span>
          </p>
          <p class="de_lr">制定人：<span>{{ detailInfo.username }}</span></p>
          <p class="de_lr">开始时间：<span>{{ detailInfo.inplanstime }}</span></p>
          <p class="de_lr">结束时间：<span>{{ detailInfo.inplanetime }}</span></p>
          <p class="de_lr">循环周期：<span>{{ detailInfo.inplanecycle }}</span></p>
          <p class="de_lr">进度：<span :class="{ 'sr1': detailInfo.inplanstate ==='1','sr2': detailInfo.inplanstate ==='2','sr3': detailInfo.inplanstate ==='3','sr4': detailInfo.inplanstate ==='4','sr5': detailInfo.inplanstate ==='5'}">{{ detailInfo.percentage }}</span></p>
        </div>
        <div class="detail_r">
          <div class="dr_top">
            <p class="xj_user" v-for="item in userList" :key="item.index">{{ item.username }}</p>
<!--            <div class="de_lr flexCB">-->
<!--              <p class="xj_use">张小福</p>-->
<!--              <div class="xj_pro"><el-progress style="width: 100%;" :percentage="100"></el-progress></div>-->
<!--            </div>-->
          </div>
          <div class="dr_bot">
            <div class="flexAC" ><div class="udi_k"></div><p class="udi_t">巡检点({{pointList.length}})</p></div>
            <div class="bot_info">
              <el-collapse  v-model="activeName" accordion class="botCollapse">
                <el-collapse-item v-for="(item, index) in pointList" :key="index" :name="index" >
                  <template slot="title">
                    <i class="iconfont iconround btIco"></i>{{ item.inpointname }}
                  </template>
                  <div class="btc_cont">
                    <p v-for="equ in item.inequlistid" :key="equ.index">{{ equ.equname }}<span>（{{ equ.adderss }}）</span></p>
<!--                    <p>电梯火灾探测器<span>（未央区文景路中段一方中港国际6层605办公区6号工位）</span></p>-->
<!--                    <p>视频联动<span>（二楼西南方向上方）</span></p>-->
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getInspecplan, addInspecplan, getplanDetails, deleteInspecplan, updatePlandisa, getInspecpoint } from '@/http/api/onsite'
import { userQuery } from '@/http/api/user'
export default {
  name: 'onsitePlan',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      planList: [],
      inplanname: '',
      date: [],
      addFromShow: false,
      addfrom: {
        inplanname: '',
        inplanuser: [],
        inplanpoint: [],
        inplantype: null,
        inplanweek: null,
        time: null,
        inplanstime: '',
        inplanetime: ''
      },
      formRules: {
        inplanname: [
          { required: true, message: '请输入巡检计划名称', trigger: 'change' }
        ],
        inplanuser: [
          { required: true, message: '请选择巡检人员', trigger: 'blur' }
        ],
        inplanpoint: [
          { required: true, message: '请选择巡检标签', trigger: 'blur' }
        ],
        time: [
          { required: true, message: '请选择时间段', trigger: 'change' }
        ],
        inplantype: [
          { required: true, message: '请选择循环周期', trigger: 'change' }
        ]
      },
      time: null,
      planUserOptions: [],
      planPointOptions: [],
      showDay: false,
      showMonth: false,
      dayOptions1: [
        { value: '1', label: '星期一' },
        { value: '2', label: '星期二' },
        { value: '3', label: '星期三' },
        { value: '4', label: '星期四' },
        { value: '5', label: '星期五' },
        { value: '6', label: '星期六' },
        { value: '7', label: '星期日' }
      ],
      dayOptions2: [
        { value: '1', label: '一月' },
        { value: '2', label: '二月' },
        { value: '3', label: '三月' },
        { value: '4', label: '四月' },
        { value: '5', label: '五月' },
        { value: '6', label: '六月' },
        { value: '7', label: '七月' },
        { value: '8', label: '八月' },
        { value: '9', label: '九月' },
        { value: '10', label: '十月' },
        { value: '11', label: '十一月' },
        { value: '12', label: '十二月' }
      ],
      dayValue: [],
      monthValue: '',
      detailShow: false,
      detailNewShow: false,
      detailInfo: {},
      inplanuser: [],
      equlist: [],
      inplanpoint: [],
      roleBtnArray: [],
      activeName: 0,
      userList: [],
      pointList: []
    }
  },
  beforeMount () {
    if (this.roleBtn.length > 0) {
      this.roleBtnArray = this.roleBtn
      // console.log(this.roleBtnArray)
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      const params = {
        inplanname: this.inplanname,
        companyid: this.companyid,
        page_index: this.page,
        page_size: this.size
      }
      getInspecplan(params).then(res => {
        if (res.status === '1') {
          this.planList = res.data.data
          // console.log(this.planList)
          this.total = res.data.recordcount
        } else {
          this.planList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 巡检人员
      userQuery({companyid: this.companyid}).then(res => {
        if (res.status === '1') {
          this.planUserOptions = res.data.data
        } else {
          this.planUserOptions = []
        }
      })

      // 巡检点 下拉
      getInspecpoint({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.planPointOptions = res.data.data
        } else {
          this.planPointOptions = []
        }
      })
    },
    changeType (val) {
      if (val == 1) {
        this.showDay = true
        this.showMonth = false
        this.addfrom.inplantype == '1'
        this.monthValue = ''
      } else if (val == 2) {
        this.showMonth = true
        this.showDay = false
        this.addfrom.inplantype == '2'
        this.dayValue = []
      }
    },
    // 新增
    handleAdd () {
      this.addFromShow = true
      this.addfrom.inplantype = '1'
      this.showDay = true
      this.showMonth = false
      this.getInit()
    },
    AddFromConfirm () {
      let sTime = ''
			let eTime = ''
			if (this.addfrom.time != null) {
				sTime = this.addfrom.time[0]
				eTime = this.addfrom.time[1]
			}
      let inplanuser = this.addfrom.inplanuser.join(',')
			let inplanpoint = this.addfrom.inplanpoint.join(',')
			if (this.addfrom.inplantype == '1') {
				this.addfrom.inplanweek = this.dayValue.join(',')
			} else {
				this.addfrom.inplanweek= this.monthValue
			}
			if (this.addfrom.inplanweek == '' || this.addfrom.inplanweek == undefined ) {
				this.$message.warning('请选择循环周期')
			}
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.companyid,
          inplanname: this.addfrom.inplanname,
          inplanuser: this.addfrom.inplanuser,
          inplanpoint: this.addfrom.inplanpoint,
          inplantype: this.addfrom.inplantype,
          inplanweek: this.addfrom.inplanweek,
          inplanstime: sTime,
          inplanetime: eTime,
        }
        addInspecplan(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.$refs['addFrom'].resetFields()
      this.monthValue = ''
      this.dayValue = []
      this.addFromShow = false
    },
    handleDetail (row) {
      // this.detailShow = true
      this.detailNewShow = true
      this.detailInfo = row
      this.activeName = 0
      console.log(this.detailInfo)
      getplanDetails({ inplanid: row.inplanid }).then(res => {
        if (res.status === '1') {
          this.userList = res.data.inplaneuserlist
          this.pointList = res.data.inplanpointlist
          // if (res.data.length != 0) {
          //   this.inplanuser = res.data[0].inplanuser
          //   this.equlist = res.data[0].equlist
          //   this.inplanpoint = res.data[0].inplanpoint
          // }
        }
      })
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteInspecplan({ inplanid: row.inplanid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    handleStop (row) {
      updatePlandisa({ inplanid: row.inplanid }).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.inplanname = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.onplan_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.onplan_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.onplan_table {
  margin-top: 20px;
}
.typeBox {
  width: 100%;
  margin-top: 24px;
}
.ud_left{
  width: 520px;
}
.udi_p,.udi_s,.udiS,.udiIP,.de_lr{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.de_lr{
  color: #333333;
}
.de_lr span{
  color: #8291A1;
}
.de_lr .sr1, .de_lr .sr2, .state1, .state2{ color: #196DF7; }
.de_lr .sr3, .state3{ color: #E33730; }
.de_lr .sr4,.state4{
  color: #FF944C;
}
.de_lr .sr5,.state5{
  color: #8291A1;
}
.udiIP{
  width: 48%;
}
.udi_s{
  width: 24%;
  margin-right: 1%;
}
.udiS{
  width: 49%;
  margin-right: 1%;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_c {
  float: left;
  display: flex;
  width: 160px;
  height: 40px;
  background: #F6F9FF;
  margin-left: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.ud_left{
  width: 700px;
}
.detail_info{
  width: 100%;
  height: 480px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.detail_l{
  width: 55%;
  padding-right: 20px;
  border-right: 2px solid #EBEDF5;
}
.detail_r{
  width: 45%;
  padding-left: 20px;
}
.detail_l,.detail_r{
  box-sizing: border-box;
  height: 100%;
}
.dr_top,.bot_info{
  width: 100%;
  height: 135px;
  overflow: auto;
  box-sizing: border-box;
  padding-right: 5px;
  scrollbar-width: none;/* //火狐兼容 */
}
.dr_top{
  display: flex;
  flex-wrap: wrap;
}
.dr_bot{
  width: 100%;
  height: calc(100% - 135px);
  margin-top: 15px;
}
.bot_info{
  width: 100%;
  height: calc(100% - 30px);
  padding-top: 12px;
}
.dr_top::-webkit-scrollbar, .bot_info::-webkit-scrollbar{/*滚动条整体样式*/
  width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.dr_top::-webkit-scrollbar-thumb, .bot_info::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #CADDFE;
}
.dr_top::-webkit-scrollbar-track, .bot_info::-webkit-scrollbar-track {/*滚动条里面轨道*/
  border-radius: 10px;
  background: #EDEDED;
}
.xj_user{
  width: 90px;
  height: 40px;
  color: #196DF7;
  line-height: 40px;
  text-align: center;
  background: #F6F9FF;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.xj_use{
  width: 15%;
  text-align: right;
}
.xj_pro{
  width: 85%;
  padding-left: 5px;
  box-sizing: border-box;
}
.xj_pro >>> .el-progress-bar{
  padding-right: 50px;
}
.botCollapse, .botCollapse >>> .el-collapse-item__header,
.botCollapse >>> .el-collapse-item__wrap{
  border: none;
}
.botCollapse .btIco, .botCollapse >>> .el-collapse-item__arrow{
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
}
.botCollapse .btIco{
  color: #196DF7;
}
.botCollapse >>> .el-collapse-item__header{
  height: 30px;
  line-height: 30px;
  font-weight: 800;
  font-size: 14px;
}
.botCollapse >>> .el-collapse-item__content{
  padding-bottom: 0px;
}
.btc_cont{
  padding-left: 22px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
}
.btc_cont span{
  color: #999;
}
</style>
